import React from "react";
import PropTypes from "prop-types";
import Gallery from "react-grid-gallery";
import ModalImage from "react-modal-image";
import { MSG_STATUS } from "../../../constants/messages";
import { MESSAGE_TYPES } from "../../../constants/messages";
import { Twemoji } from "react-emoji-render";
import ReactAudioPlayer from "react-audio-player";
import ReactHtmlParser from 'react-html-parser';
import Linkify from 'react-linkify';


export const OutgoingMessage = ({ message, position, handler, primaryColor, isWidget, locale }) => {

  let fileType = "file";
  let file = null;
  let agent_name = message.agent_name;

  if (message.status == MSG_STATUS.SENT && agent_name == null) {
    agent_name =
      message.message_type == MESSAGE_TYPES.SENT_EXTERNALLY
        ? "Channel"
        : "Message Bot";
  }

  if (message.attachments.length) {
    file = message.attachments[0];
    fileType = file.type.split("/")[0];
  }

  const date = new Date(message.created_at); // Replace this with your actual date, e.g., created_at

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    // timeZone: "Asian/Singapore",
    locale,
  };
  const formatter = new Intl.DateTimeFormat(locale, options);
  const formattedDate = formatter.format(date);
  const messageDisplay = ({ content }) => {
    const processMessage = (text) => {
      // Use react-html-parser to parse the HTML content
      const parsedContent = ReactHtmlParser(text, {
        // Add your custom parsing options if needed
      });
      // Wrap the parsed content with Linkify to automatically linkify URLs
      return (
        <span className="text-content-message">
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {parsedContent}
          </Linkify>
        </span>
      );
    };
    const processedMessage = processMessage(content);
    return <div>{processedMessage}</div>;
  };
  return (
    <div className="row-fluid" id={message.id}>
      <div className="col-12 message">
        <div className="sent_message" style={{ background: primaryColor }}>
          {position.first && !isWidget && (
            <span className="mb-2 d-block font-weight-bold">{agent_name}</span>
          )}
          {!isWidget && (
            <><input className="reply-btn"></input><div className="reply-menu">
              <span
                type="button"
                className="reply-menu-item"
                onClick={() => {
                  handler(message);
                  document.getElementById("conversation-textarea").focus();
                } }
              >
                {" "}
                Reply
              </span>
              <br></br>
              <span
                type="button"
                className="reply-menu-item"
                onClick={() => {
                  let url = `${state.current}/${message.id}`;

                  $.ajax({
                    url: url,
                    type: "DELETE",
                    headers: {
                      "X-CSRF-Token": $('meta[name="csrf-token"]').attr(
                        "content"
                      ),
                    },
                    success: function () {
                      toastr.success("Deleted successfully!");
                    },
                  });
                } }
              >
                {" "}
                Delete
              </span>
            </div></>
          )}
          {message.quoted_message_id ? (
            <a href={`?tab=all&by_id=${message.conversation_id}&message_id=${message.quoted_message_id}&on_page=${message.quoted_message.on_page}`} style={{textDecoration: 'none'}}>
              <div className="reply-container">
                <p className="reply-title">
                  {message.quoted_message.name
                    ? message.quoted_message.name
                    : "You"}{" "}
                </p>
                <p className="reply-text">{message.quoted_message.content} </p>
              </div>
            </a>
          ) : null}

          {message.content && messageDisplay({ content: message.content })}

          {file &&
            (() => {
              if (fileType === "image") {
                return (
                  <span className="file-attachment-message-preview">
                    <ModalImage
                      className="img-preview-file-attached"
                      small={file.url}
                      medium={file.url}
                      large={file.url}
                      alt={message.content}
                      hideDownload={false}
                      hideZoom
                      showRotate={false}
                    />
                  </span>
                );
              }
              if (fileType === "audio") {
                return (
                  <div>
                    <ReactAudioPlayer src={file.url} controls />
                  </div>
                );
              }
              if (fileType === "video") {
                return (
                  <div>
                    <video width="100%" controls>
                      <source src={file.url} type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                  </div>
                );
              }
              if (fileType === "sticker") {
                return (
                  <span className="file-attachment-message-preview file-border">
                    <a target="_blank" href={file.url} rel="noreferrer">
                      Sticker &gt;
                    </a>
                  </span>
                );
              }
              return (
                <span className="file-attachment-message-preview file-border">
                  <a target="_blank" href={file.url} rel="noreferrer">
                    Show file attachment &gt;
                  </a>
                </span>
              );
            })()}
        </div>

        <span className="message-datetime mr-1">
          {formattedDate}
          {isWidget ? (
            ""
          ) : (
            <span
              className={message.status === MSG_STATUS.ERROR ? "text-red" : ""}
            >
              <div className={message.status} />
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

OutgoingMessage.defaultProps = {
  message: PropTypes.shape({
    content: "",
    file: "",
    created_at: "",
    status: "",
  }),
};

OutgoingMessage.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string,
    file: PropTypes.string,
    created_at: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default OutgoingMessage;
